<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div
          class="shadow overflow-hidden border-b border-gray-200 rounded-t-md bg-white"
        >
          <div class="lg:flex lg:items-center lg:justify-between m-5">
            <div class="flex-1 min-w-0">
              <h2
                class="text-xl font-medium leading-7 body__header--text sm:text-3xl sm:truncate"
              >
                Hosts
              </h2>
            </div>
            <div class="mt-5 flex lg:mt-0 lg:ml-4">
              <span class="hidden sm:block">
                <button
                  @click="openNewHostForm = !openNewHostForm"
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 encom_ring_color"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  New host
                </button>
              </span>
            </div>
          </div>
          <div class="pl-10 pr-10 mt-1" v-if="!openNewHostForm">
            <ViewHost
              :key="hostId"
              v-if="openHostView && hostId"
              :hostId="hostId"
              @closeHostView="openHostView = false"
            />
          </div>
          <div class="p-1 mt-1" v-if="openNewHostForm">
            <NewHost
              @closeNewHostForm="openNewHostForm = false"
              @activeBanner="(activeBanner = true), getHosts(), (key = !key)"
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
          <div class="p-1 mt-1" v-if="!openNewHostForm">
            <TablePagination
              :openHostView="openHostView"
              @openHost="openViewHost($event)"
              :tableData="sortedHosts()"
              rows="20"
              @sort="sort($event)"
              @activeBanner="(activeBanner = true), getHosts(), (key = !key)"
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import TablePagination from "../../components/TablePagination.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import ViewHost from "../../components/hosting/ViewHost.vue";
import NewHost from "../../components/hosting/NewHost.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  title() {
    return `Hosts - ${localStorage.getItem("title")}`;
  },
  props: ["customerId"],
  components: {
    TablePagination,
    SuccessBanner,
    ViewHost,
    NewHost,
    Loading,
  },
  data() {
    return {
      openNewHostForm: false,
      isLoading: true,
      fullPage: false,
      currentSort: "id",
      currentSortDir: "asc",
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      hostId: null,
      hosts: [],
      openHostView: false,
    };
  },
  methods: {
    openViewHost(id) {
      this.hostId = id;
      this.openHostView = true;
    },
    async getHosts() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hosts`
        );
        console.log(res.data);
        this.hosts = res.data;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },

    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    sortedHosts() {
      return this.hosts.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    filteredHosts() {
      return this.sortedHosts().filter((host) => {
        // return (
        //   ticket.ticketable.name
        //     .toLowerCase()
        //     .includes(this.search.toLowerCase()) ||
        //   ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
        //   this.formatDate(ticket.created_at)
        //     .toLowerCase()
        //     .includes(this.search.toLowerCase()) ||
        //   this.formatDate(ticket.updated_at)
        //     .toLowerCase()
        //     .includes(this.search.toLowerCase())
        // );
      });
    },
  },
  mounted() {
    this.getHosts();
  },
};
</script>

<style></style>
