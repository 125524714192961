<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="flex flex-col p-10">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <form
        @submit.prevent="onSubmit('Host created successfully')"
        class="space-y-8 divide-y divide-gray-200 bg-white shadow-md pr-6 pl-6 pb-2 rounded"
      >
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="flex justify-between">
              <h3 class="text-lg leading-6 font-medium body__header--text">
                Host {{ host.code }}
              </h3>
              <a href="javascript:void(0)" @click="showForm = !showForm">
                <svg
                  v-if="!showForm"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <svg
                  v-if="showForm"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </a>
            </div>
            <div v-if="showForm" class="space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="client"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Client
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-1">
                  <quick-search @action="setCustomertId($event)" />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    required=""
                    v-model="form.name"
                    id="name"
                    name="name"
                    type="text"
                    autocomplete="name"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="code"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Code
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    required=""
                    v-model="form.code"
                    id="code"
                    name="code"
                    type="text"
                    autocomplete="code"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="address"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Address
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    required=""
                    v-model="form.address"
                    id="address"
                    name="address"
                    type="text"
                    autocomplete="name"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="vlan"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  VLAN
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.vlan"
                    type="text"
                    name="vlan"
                    id="vlan"
                    autocomplete="vlan"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="config"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  config
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.config"
                    type="text"
                    name="config"
                    id="config"
                    autocomplete="config"
                    class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="infrastructure"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  infrastructure
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.infrastructure"
                    id="infrastructure"
                    name="infrastructure"
                    autocomplete="infrastructure"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="infrastructure in infrastructures"
                      :key="infrastructure.id"
                      :value="infrastructure.id"
                      >{{ infrastructure.name }}</option
                    >
                  </select>
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="provider"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  provider
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.provider"
                    id="provider"
                    name="provider"
                    autocomplete="provider"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="provider in providers"
                      :key="provider.id"
                      :value="provider.id"
                      >{{ provider.name }}</option
                    >
                  </select>
                </div>
              </div>

              <!-- <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="nic-id"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  nic ID
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.nicId"
                    id="nic-id"
                    name="nic-id"
                    autocomplete="nic-id"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option v-for="nic in nics" :key="nic.id" :value="nic.id">{{
                      nic.name
                    }}</option>
                  </select>
                </div>
              </div> -->

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="shi"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  SHI
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.shi"
                    id="shi"
                    name="shi"
                    type="text"
                    autocomplete="shi"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="data-center"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  data center
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.dataCenter"
                    id="data-center"
                    name="data-center"
                    autocomplete="data-center"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="dataCenter in dataCenters"
                      :key="dataCenter.id"
                      :value="dataCenter.id"
                      >{{ dataCenter.name }}</option
                    >
                  </select>
                </div>
              </div>

              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="service-type"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Service type
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.serviceType"
                    id="service-type"
                    name="service-type"
                    autocomplete="service-type"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="serviceType in serviceTypes"
                      :key="serviceType.id"
                      :value="serviceType.id"
                      >{{ serviceType.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="host-type"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Host type
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.hostType"
                    id="host-type"
                    name="host-type"
                    autocomplete="host-type"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="hostType in hostTypes"
                      :key="hostType.id"
                      :value="hostType.id"
                      >{{ hostType.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="host-role"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Host Role
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.hostRole"
                    id="host-role"
                    name="host-role"
                    autocomplete="host-role"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="hostRole in hostRoles"
                      :key="hostRole.id"
                      :value="hostRole.id"
                      >{{ hostRole.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="function"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  function
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.function"
                    id="function"
                    name="function"
                    autocomplete="function"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="hostFunction in hostFunctions"
                      :key="hostFunction.id"
                      :value="hostFunction.id"
                      >{{ hostFunction.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="shell-type"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Shell type
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.shellType"
                    id="shell-type"
                    name="shell-type"
                    autocomplete="shell-type"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="shellType in shellTypes"
                      :key="shellType.id"
                      :value="shellType.id"
                      >{{ shellType.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="operational-system"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  Operational System
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    v-model="form.operationalSystem"
                    id="operational-system"
                    name="operational-system"
                    autocomplete="operational-system"
                    class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option
                      v-for="operationalSystem in operationalSystems"
                      :key="operationalSystem.id"
                      :value="operationalSystem.id"
                      >{{ operationalSystem.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="installation"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Installation
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="form.installation"
                    id="installation"
                    name="installation"
                    type="text"
                    autocomplete="installation"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="outsourced"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                >
                  managed
                </label>
                <Switch
                  id="outsourced"
                  v-model="form.outsourced"
                  class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    class="pointer-events-none absolute bg-white w-full h-full rounded-md"
                  />
                  <span
                    aria-hidden="true"
                    :class="[
                      form.outsourced ? 'bg-indigo-600' : 'bg-gray-200',
                      'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
                    ]"
                  />
                  <span
                    aria-hidden="true"
                    :class="[
                      form.outsourced ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
                    ]"
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end" v-if="showForm">
            <button
              @click="$emit('closeNewHostForm')"
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md body__button text-white"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import SuccessBanner from "../../components/SuccessBanner.vue";
import HoverTooltip from "../../components/HoverTooltip.vue";
import { Switch } from "@headlessui/vue";
import QuickSearch from "../QuickSearch.vue";

const todayDate = new Date().toLocaleDateString();

export default {
  props: ["hostId"],
  data() {
    return {
      search: "",
      quickData: [],
      showForm: true,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      host: "",
      infrastructures: [],
      providers: [],
      nics: [],
      dataCenters: [],
      serviceTypes: [],
      hostTypes: [],
      hostRoles: [],
      hostFunctions: [],
      shellTypes: [],
      operationalSystems: [],
      customerId: null,
      installedSoftwares: [],
      hostSoftwares: [],
      addSoftwares: [],
      hostNetworkInterfaces: [],
      showIdx: null,
      showEdit: false,
      openNewForm: false,
      form: {
        client: "",
        name: "",
        code: "",
        address: "",
        vlan: null,
        config: null,
        infrastructure: null,
        provider: null,
        nicId: "",
        shi: "",
        dataCenter: null,
        name: "",
        serviceType: 0,
        hostType: 0,
        hostRole: 0,
        function: null,
        shellType: 0,
        operationalSystem: 0,
        installation: todayDate,
        outsourced: 0,
      },
    };
  },
  components: {
    Switch,
    SuccessBanner,
    HoverTooltip,
    QuickSearch,
  },
  methods: {
    setCustomertId(event) {
      let id = event.searchable_id;
      this.customerId = id;
    },
    async onSubmit(msg) {
      let data = {
        customer_id: this.customerId,
        code: this.form.code,
        name: this.form.name,
        address: this.form.address,
        vlan: this.form.vlan,
        host_os_id: this.form.operationalSystem,
        host_role_id: this.form.hostRole,
        host_service_id: this.form.serviceType,
        host_shell_id: this.form.shellType,
        host_type_id: this.form.hostType,
        host_function_id: this.form.function,
        host_supplier_id: this.form.provider,
        managed: this.form.outsourced,
        config: this.form.config,
        infrastructure_id: this.form.infrastructure,
        supplier_identification: null,
        datacenter_id: this.form.dataCenter,
        comment: "string",
        installed_at: this.form.installation,
        deleted_at: null,
      };
      try {
        const res = await axios.post(
          `${this.$cookie.getCookie("API")}/api/v1/hosts`,
          data
        );
        this.$emit("activeBanner");
        this.$emit("successMsg", msg);
        this.$emit("isSuccess", true);
        this.$emit("closeNewHostForm");
      } catch (error) {
        this.$emit("activeBanner");
        this.$emit("successMsg", "Sorry, something went wrong.");
        this.$emit("isSuccess", false);
        this.$emit("closeNewHostForm");
        this.errorHandling(error);
      }
    },
    async getInfrastructures() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/infrastructures`
        );
        this.infrastructures = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getFunctions() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostFunctions`
        );
        this.hostFunctions = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getOperationalSystems() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostOs`
        );
        this.operationalSystems = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getDatacenters() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/datacenters`
        );
        this.dataCenters = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getServices() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostServices`
        );
        this.serviceTypes = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostRoles() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostRoles`
        );
        this.hostRoles = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostTypes() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostTypes`
        );
        this.hostTypes = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostSoftwares() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostSoftwares`
        );
        this.hostSoftwares = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getHostProviders() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostSuppliers`
        );
        this.providers = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getShells() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostShells`
        );
        this.shellTypes = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getCode() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/helpers/strings/code`
        );
        this.form.code = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getQuick(term) {
      try {
        if (term) {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/getQuick?term=${term}`
          );
          this.quickData = res.data;
        }
      } catch (error) {
        this.errorHandling(error);
      }
    },
  },
  mounted() {
    this.getInfrastructures();
    this.getFunctions();
    this.getOperationalSystems();
    this.getDatacenters();
    this.getServices();
    this.getHostRoles();
    this.getHostTypes();
    this.getHostSoftwares();
    this.getHostProviders();
    this.getShells();
    this.getCode();
  },
  watch: {
    search: function(val) {
      if (val.length > 3) this.getQuick(val);
    },
  },
};
</script>

<style>
.multiple_select_height {
  height: 200px;
}
</style>
